<template>
  <div class="body">
    <Navbar v-bind:show-scroll-points="false" />

    <b-row align-v="center" class="rowp row1" cols="1" cols-lg="2">
      <b-col class="row1-col1">
        <h1 class="row1-title">
          {{ $t("instructions_title_p1") }}
          <br />
          <span class="text-rect-highlight-orange">
            {{ $t("instructions_title_p2") }}</span
          >
        </h1>

        <div>
          {{ $t("instructions_text_p1") }}
          <br />
          <br />
          {{ $t("instructions_text_p2") }}
        </div>

        <div class="osm-page text-center">
          <a href="https://www.openstreetmap.org" target="_blank">
            {{ $t("instructions_goto_osm") }}
          </a>
        </div>
      </b-col>

      <b-col>
        <img
          alt="Showcase image"
          class="row1-img"
          src="/imgs/instructions/img_osm.png"
        />
      </b-col>
    </b-row>

    <div class="row2 ml-auto mr-auto">
      <h2>{{ $t("instructions_howto") }}</h2>
    </div>

    <b-row class="rowp" cols="1" cols-lg="2">
      <b-col class="row2-col">
        <img
            alt="kdynakoupit.cz step1"
            class="row2-col-img"
            src="/imgs/instructions/21.png"
            width="80%"
        />
        <div class="row2-col-text" v-html="$t('instructions_step1')"></div>
      </b-col>

      <b-col class="row2-col">
        <img
            alt="kdynakoupit.cz step2"
            class="row2-col-img"
            src="/imgs/instructions/1.png"
            width="80%"
        />
        <div class="row2-col-text" v-html="$t('instructions_step2')"></div>
      </b-col>
    </b-row>

    <b-row class="rowp" cols="1" cols-lg="2">
      <b-col class="row2-col">
        <img
            alt="kdynakoupit.cz step3"
            class="row2-col-img"
            src="/imgs/instructions/2.png"
        />
        <div class="row2-col-text" v-html="$t('instructions_step3')"></div>
      </b-col>

      <b-col class="row2-col">
        <img
            alt="kdynakoupit.cz step4"
            class="row2-col-img"
            src="/imgs/instructions/15.png"
        />
        <div class="row2-col-text" v-html="$t('instructions_step4')"></div>
      </b-col>
    </b-row>

    <b-row class="rowp" cols="1" cols-lg="2">
      <b-col class="row2-col">
        <img
            alt="kdynakoupit.cz step5"
            class="row2-col-img"
            src="/imgs/instructions/16.png"
        />
        <div class="row2-col-text" v-html="$t('instructions_step5')"></div>
      </b-col>

      <b-col class="row2-col">
        <img
            alt="kdynakoupit.cz step6"
            class="row2-col-img"
            src="/imgs/instructions/3.png"
        />
        <div class="row2-col-text" v-html="$t('instructions_step6')"></div>
      </b-col>
    </b-row>

    <b-row class="rowp" cols="1" cols-lg="2">
      <b-col class="row2-col">
        <img
            alt="kdynakoupit.cz step7"
            class="row2-col-img"
            src="/imgs/instructions/4.png"
        />
        <div class="row2-col-text" v-html="$t('instructions_step7')"></div>
      </b-col>

      <b-col class="row2-col">
        <img
            alt="kdynakoupit.cz step8"
            class="row2-col-img"
            src="/imgs/instructions/5.png"
        />
        <div class="row2-col-text" v-html="$t('instructions_step8')"></div>
      </b-col>
    </b-row>

    <b-row class="rowp" cols="1" cols-lg="2">
      <b-col class="row2-col">
        <img
            alt="kdynakoupit.cz step9"
            class="row2-col-img"
            src="/imgs/instructions/6.png"
        />
        <div class="row2-col-text" v-html="$t('instructions_step9')"></div>
      </b-col>

      <b-col class="row2-col">
        <img
            alt="kdynakoupit.cz step10"
            class="row2-col-img"
            src="/imgs/instructions/7.png"
        />
        <div class="row2-col-text" v-html="$t('instructions_step10')"></div>
      </b-col>
    </b-row>

    <b-row class="rowp" cols="1" cols-lg="2">
      <b-col class="row2-col">
        <img
            alt="kdynakoupit.cz step11"
            class="row2-col-img"
            src="/imgs/instructions/8.png"
        />
        <div class="row2-col-text" v-html="$t('instructions_step11')"></div>
      </b-col>

      <b-col class="row2-col">
        <img
            alt="kdynakoupit.cz step12"
            class="row2-col-img"
            src="/imgs/instructions/9.png"
        />
        <div class="row2-col-text" v-html="$t('instructions_step12')"></div>
      </b-col>
    </b-row>

    <b-row class="rowp" cols="1" cols-lg="2">
      <b-col class="row2-col">
        <img
            alt="kdynakoupit.cz step13"
            class="row2-col-img"
            src="/imgs/instructions/10.png"
        />
        <div class="row2-col-text" v-html="$t('instructions_step13')"></div>
      </b-col>

      <b-col class="row2-col">
        <img
            alt="kdynakoupit.cz step14"
            class="row2-col-img"
            src="/imgs/instructions/11.png"
        />
        <div class="row2-col-text" v-html="$t('instructions_step14')"></div>
      </b-col>
    </b-row>

    <b-row class="rowp" cols="1" cols-lg="2">
      <b-col class="row2-col">
        <img
            alt="kdynakoupit.cz step15"
            class="row2-col-img"
            src="/imgs/instructions/12.png"
        />
        <div class="row2-col-text" v-html="$t('instructions_step15')"></div>
      </b-col>

      <b-col class="row2-col">
        <img
            alt="kdynakoupit.cz step16"
            class="row2-col-img"
            src="/imgs/instructions/17.png"
        />
        <div class="row2-col-text" v-html="$t('instructions_step16')"></div>
      </b-col>
    </b-row>

    <b-row class="rowp" cols="1" cols-lg="2">
      <b-col class="row2-col">
        <img
            alt="kdynakoupit.cz step17"
            class="row2-col-img"
            src="/imgs/instructions/18.png"
        />
        <div class="row2-col-text" v-html="$t('instructions_step17')"></div>
      </b-col>

      <b-col class="row2-col">
        <img
            alt="kdynakoupit.cz step18"
            class="row2-col-img"
            src="/imgs/instructions/19.png"
        />
        <div class="row2-col-text" v-html="$t('instructions_step18')"></div>
      </b-col>
    </b-row>

    <b-row class="rowp" cols="1" cols-lg="2">
      <b-col class="row2-col">
        <img
            alt="kdynakoupit.cz step19"
            class="row2-col-img"
            src="/imgs/instructions/20.png"
        />
        <div class="row2-col-text" v-html="$t('instructions_step19')"></div>
      </b-col>

      <b-col class="row2-col">
        <div class="row2-col-text" v-html="$t('instructions_step20')"></div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";

export default {
  name: "Info",
  components: {
    Navbar,
  },
};
</script>

<style lang="less" scoped>
@import "../assets/css/styles";

.rowp {
  padding-left: 2%;
  padding-right: 2%;
  margin-left: 0;
  margin-right: 0;
}

.row1 {
  margin-top: 32px;
}

.row1-title {
  color: @color-blue-dark;
  margin-bottom: 16px;
}

.row1-img {
  max-width: 95%;
  max-height: 720px;
}

.row1-col1 {
  text-align: center;
}

.btn-video {
  cursor: pointer;
  margin-top: 16px;
}

.row2 {
  margin-top: 60px;
  margin-bottom: 32px;
}

.row2-col {
  padding-bottom: 64px;
}

.row2-col-img {
  width:100%;
  margin-bottom: 30px;
}

.row2-col-title {
  margin-bottom: 12px;
}

.row2-col-text {
  margin-bottom: 24px;
  margin: 0 auto;
  text-align: left;
  width: 100%;
}


.row2-col-btn-search {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  box-shadow: none;
  border: none;
  border-radius: 7px;
  background-color: @color-green;
  color: @color-blue-dark;
  outline: none;
  height: 58px;
  width: 198px;
  align-items: center;
  justify-content: center;
  font-weight: 500;
}


.osm-page {
    background: @color-green;
    border-radius: 8px;
    padding: 12px;
    margin: 15px;
    width: 90%;
    font-weight: 500;
  }

  .osm-page a {
    color: #0c243d;
  }

@media (min-width: @lg) {
  .rowp {
    padding-left: 5%;
    padding-right: 5%;
  }

  .row1 {
    margin-top: 50px;
  }

  .row1-col1 {
    padding-right: 12.5%;
    text-align: left;
  }

  .row1-title {
    margin-bottom: 36px;
  }

  .btn-video {
    margin-top: 36px;
  }

  .row1-img {
    max-width: 80%;
  }

  .row2 {
    margin-top: 100px;
    margin-bottom: 60px;
    width: 50%;
  }

  .row2-col img {
    width: 80%;
  }

  .row2-col-img {
    max-width: 80%;
  }

  .row2-col-title {
    margin-bottom: 16px;
  }

  .row2-col-text {
    min-height: 80px;
    width: 80%;
  }

  .osm-page {
    width: 40%;
  }


}
</style>
